import React from 'react';

import PageLayout from '../../../views/Layout/Page';
import PublicProfile, { PublicProfileTabs } from '../../../views/User/PublicProfile';

export default function UserProfilePage({ params }) {
  return (
    <PageLayout pageview="user_profile">
      <PublicProfile hash={params.hash} tab={PublicProfileTabs.PROFILE} />
    </PageLayout>
  );
}
